import React, { useState } from 'react';
import { View, Text,Image, StyleSheet,Platform,Dimensions, TouchableOpacity, Animated } from 'react-native';
import WavyleftBar from './WavyleftBar';
import { DefaultThemeColors, DarkThemeColors,ThemeOne,ThemeTwo } from "../utils/constants/Colors";
import topImage from '../../assets/judgeCourtBanner.png';
import topIcon from '../../assets/jc.png';
const windowWidth = Dimensions.get('window').width;
const Colors2 = ThemeOne;
//const Colors1 = ThemeTwo;
const TopBar = ({ children }) => {
 

  return (
   
      <View style={styles.topBar}>
         <View style={styles.limage}> 
        <View  style={styles.logoShadow}>
        <Image
        style={styles.logo}
        source={topIcon}
      /></View></View>
        <View style={styles.rimage}> 
       
        <View style={{flexDirection:'row'}}><Image
        style={{
        
          width:225,
          height:27,
          marginLeft:'24.95%',
          marginTop: 3
         // alignSelf:'center'
          //width:'70%'
      
         
        }}
        source={topImage}
      /><Text style={styles.textSs}>1</Text></View> 
      <Text style={styles.textS}>(For Judge Court Diary & Case Search)</Text>
    <Text style={styles.textSP}>Powered By: Siddique Enterprise </Text>
      </View>
       
       
      
        </View>
     
    
  );
};

const styles = StyleSheet.create({
  
  topBar: {
   // top:0,
    width: '100%',
   
   // height:75,
  flexDirection:'row',
   backgroundColor:'#0373BB',
//  elevation:0,
 zIndex:-1,

  },
  textS:{
   
    fontSize:17,
    color:'#ff0',
   marginLeft:'21.7%',
    fontFamily:'Aachen-BT',
    lineHeight:19,
    //textAlign:'center'
  },
  textSs:{
  position:'absolute', 
  top:0,
  right:0,
    fontSize:12,
    fontWeight:'bold',
    color:'#fff',
    margin:'10 10 0 0',
    alignItems:'flex-end',    
    lineHeight:22,
    //textAlign:'center'
  },
  textSP:{
   
    fontSize:22,
    color:'#ff0',
    marginLeft:'19.5%',
    fontFamily:'Aachen-BT',
    lineHeight:22,
    //textAlign:'center'
  },
  logo:{
        
    width:70,
    height:70,
    //width:'70%'
    borderRadius:44,
    
  
   },
  logoShadow:{
        
    width:65,
    height:65,
    //width:'70%'
    borderRadius:40,
    shadowColor: "#fff",
    shadowOpacity: 0.9,
    shadowRadius: 20,
    shadowOffset: {
      height: 2,
      width: 0
    },
    //elevation:16,
   },

  limage: {
    width:'25%',
    paddingTop:2,
   // paddingLeft:150
 alignItems:'flex-end'
   },
  rimage: {
   width:'75%',
   padding:0,
   flexDirection:'column',
  //  justifyContent:'center',
  //  alignItems:'center',

  },
  leftBarMobiole: {
    width: 'auto',
    padding:5,
    flex: 1,
    marginLeft:0,
    flexDirection: 'row',
    backgroundColor: Colors2.leftbar,
    zIndex:9999
//backgroundColor: 'transparent',
  },
  backgroundImage: {
   // flex: 1,
  position: 'relative',
    width: Dimensions.get('window').width,
    bottom: 0,
    top:0,
   // left:0,
    zIndex: -1,
  },
});

export default TopBar;
